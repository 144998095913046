import React from "react";
import Layout from "../../components/UI/layout";
import SEO from "../../components/UI/seo";
import JavaDevSection from "../../components/Search/Details/Javadev/javadev"
import { graphql } from "gatsby";

const JavaDevPage = () => (    
    <Layout>
        <SEO title="Codeicus | JavaDev" />
        <JavaDevSection/>
    </Layout>
)

export default JavaDevPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;