
import React from 'react';
import * as styles from '../../../Projects/projects.module.scss'

import Tabs from  '../Tabs/tabs';
import Process from '../../../Projects/Process/process';
import Square from '../../../Projects/Square/square';
import dev4 from '../../../../images/Search/dev4.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const JavaDevSection = () => {

    const {t} = useTranslation();

    return (
        <section className={styles.projectSection}>
            <div className={styles.containProject}>
                <div className={styles.containBody}>
                    <div className={styles.contentInfoLeft}>
                        <Tabs
                        active = 'javadev'/>

                        <Square
                            title = {t("detailsPage.javadev.square.title")}
                            subtitle= {t("detailsPage.javadev.square.subtitle")}
                            description = {t("detailsPage.javadev.square.description")}
                            list = {[t("detailsPage.javadev.square.list.0")]}                            
                            color = '#f0801f'
                            image = {dev4}
                        />      
                    </div>
                    <div className={styles.contentInfoRight}>                            
                        <Process 
                            number = '1'
                            title = {t("detailsPage.javadev.process.1.title")}
                            description = {t("detailsPage.javadev.process.1.title")}
                            list= {[t("detailsPage.javadev.process.1.list.0"),
                                    t("detailsPage.javadev.process.1.list.1"),
                                    t("detailsPage.javadev.process.1.list.2")
                                ]}
                        />
                        <Process 
                            number = '2'
                            title = {t("detailsPage.javadev.process.2.title")}
                            description = {t("detailsPage.javadev.process.2.title")}
                            list= {[t("detailsPage.javadev.process.2.list.0"),
                                    t("detailsPage.javadev.process.2.list.1"),
                                    t("detailsPage.javadev.process.2.list.2")
                                ]}
                        />  
                    </div>
                </div>
            </div>
		</section>
    );
};
export default JavaDevSection;